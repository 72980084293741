import React from 'react'
import { padding, bg } from '../../utils'
import ResponsiveFigure from '../ResponsiveFigure/ResponsiveFigure'

import './PSSingleImage.scss'

const FullImage = ({mobile, desktop, caption}) => {
  return (

      <div className="fw-image">
        <ResponsiveFigure
          mobile={mobile}
          desktop={desktop}
          caption={caption}
        />
      </div>

  )
}

const ThreeQuarterImage = ({mobile, desktop, caption}) => {

  return (
    <div className="container">
      <div className="grid md:grid-cols-4">
        <div className="md:col-span-3">
          <div>
            <ResponsiveFigure
              mobile={mobile}
              desktop={desktop}
              caption={caption}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const HalfImage = ({mobile, desktop, caption}) => {
  return (
    <div className="container">
      <div className="grid md:grid-cols-2">
        <div className="md:col-span-1">
          <div>
            <ResponsiveFigure
              mobile={mobile}
              desktop={desktop}
              caption={caption}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const PSSingleImage = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    topSpacing,
    width,
    image,
    caption
  } = data


  return (
    <div className={`ps-si-component`} style={padding(topSpacing, bottomSpacing)}>
        {width === 'full' && (
          <FullImage mobile={image} desktop={image} caption={caption} />
        )}
        {width === 'threefour' && (
          <ThreeQuarterImage mobile={image} desktop={image} caption={caption} />
        )}
        {width === 'half' && (
          <HalfImage mobile={image} desktop={image} caption={caption} />
        )}
    </div>
  )
}

export default PSSingleImage
