import React from 'react'
import { padding, bg } from '../../utils'

import './PSText.scss'

const Quote = ({quote, citation, alignment}) => {

  return (
    <div className={`my-8 ml-8 md:m-0 md:col-start-8 md:col-span-4 md:flex items-${alignment}`}>
      <figure>
        <blockquote className="fancy-quote">
          <h4
            className={`${quote[0] === "“" ? 'hang' : ''}`}
            dangerouslySetInnerHTML={{__html: quote}}
          />
          <figcaption dangerouslySetInnerHTML={{__html: citation}} />
        </blockquote>
      </figure>
    </div>
  )
}


const PSText = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    topSpacing,
    alignment,
    citation,
    quote,
    content,
  } = data


  return (
    <div className='story-text' style={padding(topSpacing, bottomSpacing)}>
      <div className="container">
        <div className="md:grid md:grid-cols-12 gap-8">
          <div className="col-span-6" dangerouslySetInnerHTML={{__html: content}}></div>
          {quote && <Quote quote={quote} citation={citation} alignment={alignment} />}
        </div>
      </div>
    </div>
  )
}

export default PSText
