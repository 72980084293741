import React, { useEffect } from 'react'
import { padding, bg } from '../../utils'
import ResponsiveFigure from '../ResponsiveFigure/ResponsiveFigure'
import AOS from "aos";
import "aos/dist/aos.css";
import './PSDoubleImage.scss'

const PSDoubleImage = ({ data }) => {
  // destructure props.data into user-friendly consts
  const {
    bottomSpacing,
    images,
    topSpacing
  } = data

  useEffect(() => {
    AOS.init({
      duration : 500,
      once: true,
    });
  }, []);


  return (
    <div className="ps-double" style={padding(topSpacing, bottomSpacing)}>
      <div className="container">
        <div className="ps-double-grid grid md:grid-cols-2 gap-8" data-aos="fade-in">

          {images.map( (image, i) => (
            <div className="col-span-1 my-6 md:my-0" key={i}>
              <ResponsiveFigure
                mobile={image.image}
                desktop={image.image}
                caption={image.caption}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PSDoubleImage
