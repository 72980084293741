import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Layout'
import { last } from 'lodash-es'
import { StyledLink } from "../components/Buttons/Button"

import PSSingleImage from '../components/PSSingleImage/PSSingleImage'
import PSDoubleImage from '../components/PSDoubleImage/PSDoubleImage'
import PSText from '../components/PSText/PSText'

const switcheroo = (component, i) => {
  const compName = last(component.fieldGroupName.split('_'))
  switch(compName) {
    case 'SingleImage' : return <PSSingleImage key={i} data={component} />
    case 'DoubleImage' : return <PSDoubleImage key={i} data={component} />
    case 'Text' : return <PSText key={i} data={component} />
    default : return <div key={i}>Component not set</div>
  }
}
const StoryInfo = ({location, date}) => {
  if (date) {
    return <h3 className="uppercase">{date} <span style={{fontWeight: 'normal'}}>|</span> {location}</h3>
  } else {
    return <h3 className="uppercase">{location}</h3>
  }
}

const Story = ({
  data: {
    wpStory: { storyContent, title, featuredImage }
  }
}) => {
  const components = storyContent.storyContent

  return (
    <Layout title={title}>
      {featuredImage && (
        <div className="featured-image" style={{backgroundImage: `url(${featuredImage.node.sourceUrl})`}} />
      )}
      <div className="my-12">
        <div className="container">
          <header className="story-intro my-16 md:grid md:grid-cols-2 gap-8">
            <div>
              <h1>{title}</h1>
              <StoryInfo location={storyContent.location} date={storyContent.dateRecorded} />
            </div>
            {storyContent.download && (
              <div>
                <StyledLink download to={storyContent.download.localFile.url} target="_blank">DOWNLOAD</StyledLink>
              </div>
            )}

          </header>
        </div>
        {components && components.map((component, i) => (
          switcheroo(component, i)
        ))}
        <div className="container py-16">
          <StyledLink target="_blank" to={storyContent.download.localFile.url} download>DOWNLOAD</StyledLink>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpStory(id: { eq: $id }) {
      title
      id
      featuredImage {
        node {
          sourceUrl
          title
        }
      }
      storyContent {
        teaser
        teaserDescription
        storyContent {
          ... on WpStory_Storycontent_StoryContent_SingleImage {
            fieldGroupName
            bottomSpacing
            caption
            tintedBackground
            topSpacing
            width
            image {
              title
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 200)
                }
              }
            }
          }
          ... on WpStory_Storycontent_StoryContent_DoubleImage {
            fieldGroupName
            bottomSpacing
            topSpacing
            images {
              caption
              image {
                title
                sourceUrl
              }
            }
          }
          ... on WpStory_Storycontent_StoryContent_Text {
            fieldGroupName
            alignment
            citation
            quote
            content
            bottomSpacing
            topSpacing
          }
        }
        location
        download {
          localFile {
            url
          }
        }
        dateRecorded
        audio {
          sourceUrl
        }
      }
    }
  }
`

export default Story
